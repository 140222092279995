import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {GetParams} from '../../services/api';
import {
  fetchNotificationsService,
  updateNotificationByIdService,
} from '../../services/notificationServices';

// Action types
export enum NotificationsTypes {
  FETCH_REQUEST = '@newNotifications/FETCH_REQUEST',
  FETCH_SUCCESS = '@newNotifications/FETCH_SUCCESS',
  FETCH_FAILURE = '@newNotifications/FETCH_FAILURE',

  UPDATE_BY_ID_REQUEST = '@newNotifications/UPDATE_BY_ID_REQUEST',
  UPDATE_BY_ID_SUCCESS = '@newNotifications/UPDATE_BY_ID_SUCCESS',
  UPDATE_BY_ID_FAILURE = '@newNotifications/UPDATE_BY_ID_FAILURE',
}

// Data types
interface Notification {
  id: number;
  created: 'email' | 'web' | 'push' | 'sms';
  modified: string;
  received: string;
  channel: string;
  error: string;
  service_response: string;
  title: string;
  content: string;
  link: string;
  notification_template: number;
  user: number;
}

// State type
export interface NotificationsState extends Map<string, any> {
  readonly data: List<ImmutableMap<Notification>> | undefined;
  readonly loading: boolean;
  readonly notification: ImmutableMap<Notification>;
  readonly error: number | undefined;
  readonly dataCount: number;
}

// Create actions
export const fetchNotificationsRequest = (params?: GetParams) =>
  action(NotificationsTypes.FETCH_REQUEST, {params});

export const fetchNotificationsSuccess = (data: any) =>
  action(NotificationsTypes.FETCH_SUCCESS, {data});

export const fetchNotificationsFailure = () =>
  action(NotificationsTypes.FETCH_FAILURE);

export const updateNotificationByIdRequest = (
  notificationId: number,
  notificationData: Partial<LetrusApi.NotificationLog>,
) =>
  action(NotificationsTypes.UPDATE_BY_ID_REQUEST, {
    notificationId,
    notificationData,
  });

export const updateNotificationByIdSuccess = (data: any) =>
  action(NotificationsTypes.UPDATE_BY_ID_SUCCESS, {data});

export const updateNotificationByIdFailure = () =>
  action(NotificationsTypes.UPDATE_BY_ID_FAILURE);

// Sagas
export function* fetchNotifications(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      fetchNotificationsService,
      action.payload.params,
    );

    yield put(fetchNotificationsSuccess(response.data));
  } catch (err) {
    yield put(fetchNotificationsFailure());
  }
}

export function* updateNotificationById(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      updateNotificationByIdService,
      action.payload.notificationId,
      action.payload.notificationData,
    );
    yield put(updateNotificationByIdSuccess(response.data));
  } catch (err) {
    yield put(updateNotificationByIdFailure());
  }
}

// Selectors
const notificationsSelector = (state: ApplicationState) =>
  state.get('notifications');

export const getNotifications = createSelector(
  notificationsSelector,
  (notifications) => notifications.get('data'),
);

export const getNotificationById = createSelector(
  notificationsSelector,
  (notifications) => notifications.get('notification'),
);

export const getNotificationsCount = createSelector(
  notificationsSelector,
  (notifications) => notifications.get('count'),
);

export const getUnseenNotificationCount = createSelector(
  notificationsSelector,
  (notifications) => notifications.get('not_seen_by_teacher'),
);

export const getUpdateNotificationSuccess = createSelector(
  notificationsSelector,
  (notifications) => notifications.get('updateNotificationSuccess'),
);

export const isLoadingNotifications = createSelector(
  notificationsSelector,
  (notifications) => notifications.get('loading'),
);

// Initial state
export const INITIAL_STATE: NotificationsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  count: 0,
  unseenNotificationCount: 0,
  notification: {},
  updateNotificationSuccess: false,
});

// Reducer
export const reducer: Reducer<NotificationsState | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case NotificationsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case NotificationsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('count', action.payload.data.count)
          .set('not_seen_by_teacher', action.payload.data.not_seen_by_teacher)
          .set('data', action.payload.data.results),
      );

    case NotificationsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case NotificationsTypes.UPDATE_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', true)
          .set('error', false)
          .set('updateNotificationSuccess', false),
      );

    case NotificationsTypes.UPDATE_BY_ID_SUCCESS:
      return state.withMutations((prevState) => {
        const notificationToBeUpdatedIndex = prevState
          .get('data')
          .findIndex(
            (notification) => notification.id == action.payload.data.id,
          );

        return prevState
          .set('loading', false)
          .set('error', false)
          .set('updateNotificationSuccess', true)
          .set(
            'unseenNotitificationCount',
            prevState.get('unseenNotitificationCount') - 1,
          )
          .setIn(['data', notificationToBeUpdatedIndex], action.payload.data);
      });

    case NotificationsTypes.UPDATE_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('updateNotificationSuccess', false),
      );

    default:
      return state;
  }
};

export default reducer;
