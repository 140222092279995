import Loading from 'components/Loading';
import 'moment/locale/pt-br';
import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {injectHandTalkScript} from 'setupHandTalk';
import App from './App';
import './index.module.scss';
import * as serviceWorker from './serviceWorker';
import store, {history} from './store';

window.addEventListener('load', () => {
  /* Only import and execute the trackers setup after the whole page is loaded.
   This technique allow us to track user behaviour without increasing loading time. */
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const {setupTrackers} = require('./setupTrackers');

  setupTrackers();
  injectHandTalkScript();
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loading show />}>
      <App history={history} />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
