import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from 'react-redux';
import {logoutUserRequest} from 'store/reducers/authentication';
import {getSchoolTheme, getUserRoleName} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';
import {UserRoleName} from 'utils/types/users';
import HeaderDropdownProfile from '../HeaderDropdownProfile';

interface StateProps {
  userRole: UserRoleName;
  schoolTheme: string;
}

interface DispatchProps {
  logoutUserRequest: typeof logoutUserRequest;
}

interface OwnProps {
  name: string;
  email: string;
}

export type SidebarRightProps = StateProps & DispatchProps & OwnProps;

const SidebarRight: React.FC<SidebarRightProps> = ({
  name,
  email,
  userRole,
  schoolTheme,
  logoutUserRequest,
}) => {
  const onClickLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    logoutUserRequest();
  };

  const mountItem = (
    id: string | number,
    title: string,
    url: string,
    icon?: string,
  ) => {
    return {
      id,
      title,
      url,
      icon,
    };
  };

  const mountDropdownItems = () => {
    if (schoolTheme.includes('sae')) {
      return [mountItem(0, 'Ver meu perfil', `/perfil`)];
    }

    const dropdownItems = [
      mountItem(0, 'Editar meu perfil', `/perfil`, 'id-badge'),
      mountItem(1, 'Senhas', `/perfil`, 'key'),
      mountItem(2, 'Configurações', `/perfil`, 'cog'),
    ];

    return dropdownItems;
  };

  const getDropdownItems = () => {
    return [
      ...mountDropdownItems(),
      {
        id: 3,
        children: (
          <a
            className={`${userRole}`}
            href="/logout_and_go"
            onClick={onClickLogout}
          >
            <FontAwesomeIcon icon="sign-out-alt" />
            Sair
          </a>
        ),
      },
    ];
  };

  return (
    <HeaderDropdownProfile
      title="Perfil"
      name={name}
      email={email}
      listItems={getDropdownItems()}
    />
  );
};

export default connect(
  (state: ApplicationState) => ({
    userRole: getUserRoleName(state),
    schoolTheme: getSchoolTheme(state),
  }),
  {
    logoutUserRequest,
  },
)(SidebarRight);
