import DropdownNotifications from '../DropdownNotifications';
import DropdownProfile from '../DropdownProfile';
import './SidebarRight.scss';

interface SidebarRightProps {
  name: string;
  email: string;
}

const SidebarRight: React.FC<SidebarRightProps> = ({name, email}) => {
  return (
    <div className="SidebarRight">
      <DropdownNotifications />
      <DropdownProfile name={name} email={email} />
    </div>
  );
};

export default SidebarRight;
