import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AvatarPlaceholder from 'assets/images/avatar_placeholder.png';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {getUserRoleName} from 'store/reducers/user';
import './HeaderDropdownProfile.scss';

interface StateProps {
  userRole: string;
}

export interface OwnProps {
  listItems?: any;
  userRole?: any;
  avatar?: any;
  name?: string;
  email?: string;
  title?: string;
}

type HeaderDropdownProfileProps = OwnProps & StateProps;

const HeaderDropdownProfile: React.FC<HeaderDropdownProfileProps> = ({
  listItems,
  userRole,
  avatar = AvatarPlaceholder,
  name,
  email,
}) => {
  const [listOpen, setListOpen] = useState(false);

  const onClickOutside = () => {
    if (listOpen) {
      setListOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.id != 'HeaderDropdownProfile') {
        onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClickOutside]);

  const toggleList = () => {
    setListOpen(!listOpen);
  };

  return (
    <div className="HeaderDropdownProfile" data-ref-id="HeaderDropdownProfile">
      <button
        type="button"
        className="HeaderDropdownProfile__Button"
        onClick={toggleList}
      >
        <div className="HeaderDropdownProfile__Link">
          <span className={`icon ${userRole.toLowerCase()}`}>
            <FontAwesomeIcon icon="user-circle" size="lg" />
          </span>

          {userRole.toLowerCase() === 'reviewer' && <p>{name}</p>}
          <span className="Dropdown__Profile__Icon">
            <FontAwesomeIcon icon="caret-down" />
          </span>
        </div>
      </button>

      {listOpen && (
        <div className={`HeaderDropdownProfile__List ${userRole}`}>
          {userRole !== 'reviewer' && (
            <div>
              <img
                className="HeaderDropdownProfile__List__Avatar"
                src={avatar}
                alt="profile"
              />

              <h3 className="HeaderDropdownProfile__List__Title">{name}</h3>

              <span className="HeaderDropdownProfile__List__Description">
                {email}
              </span>
            </div>
          )}
          <ul className={`${userRole}`}>
            {listItems.map((item) => (
              <li className="HeaderDropdownProfile__List__Item" key={item.id}>
                {item.children ? (
                  item.children
                ) : (
                  <Link to={item.url} className={`${userRole}`}>
                    <FontAwesomeIcon icon={item.icon} />
                    {item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default connect((state: any) => ({
  userRole: getUserRoleName(state).toLowerCase(),
}))(HeaderDropdownProfile);
