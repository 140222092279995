import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {LetrusGlobalConfiguration} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {List} from 'immutable';

interface getRedirectUrlParams {
  isStudent?: boolean;
  isReviewer?: boolean;
}

interface UseAuthenticationProps {
  user: ImmutableMap<LetrusApi.User>;
  profile: ImmutableMap<LetrusApi.UserProfileNested>;
  terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
}

interface UseAuthenticationReturn {
  checkTermsVersion(
    termType: string,
    terms: List<ImmutableMap<LetrusGlobalConfiguration>>,
  ): string | undefined;
  getRedirectUrl({isStudent, isReviewer}: getRedirectUrlParams): string | null;
  areTermsUpdated: boolean;
  isFirstAccess: boolean;
}

export function useAuthentication({
  user,
  profile,
  terms,
}: UseAuthenticationProps): UseAuthenticationReturn {
  const arePrivacyTermsUpdated =
    user.getIn(['user_profile', 'terms_privacy_version_id']) ===
    checkTermsVersion('terms_privacy_version_id', terms);
  const areUseTermsUpdated =
    user.getIn(['user_profile', 'terms_use_version_id']) ===
    checkTermsVersion('terms_use_version_id', terms);
  const areTermsUpdated = arePrivacyTermsUpdated && areUseTermsUpdated;

  function checkTermsVersion(
    termType: string,
    terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>,
  ): string | undefined {
    const termByTermType = terms.find(
      (term: ImmutableMap<LetrusApi.LetrusGlobalConfiguration>) =>
        term.get('parameter') === termType,
    );

    return termByTermType?.get('value');
  }

  function getRedirectUrl({isStudent, isReviewer}: getRedirectUrlParams) {
    if (isStudent) {
      return 'https://aluno.letrus.com.br';
    }
    if (isReviewer) {
      return 'https://corretor.letrus.com.br';
    }
    return null;
  }

  const isFirstAccess = !profile.get('flag_first_access');

  return {
    checkTermsVersion,
    getRedirectUrl,
    areTermsUpdated,
    isFirstAccess,
  };
}
