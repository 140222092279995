import useLearningPathReport from 'features/useLearningPathReport';

const {learningPathReportColors} = useLearningPathReport();

export const criteriaChartOptions = (type: 'enem' | 'gm') => ({
  indexAxis: 'x',
  plugins: {
    legend: {
      onClick: () => {},
      labels: {
        boxWidth: 24,
        boxHeight: 8,
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
        generateLabels: () => {
          const commonStyles = {borderRadius: 2, lineWidth: 2};
          return [
            {
              text: 'Satisfatório',
              fillStyle: learningPathReportColors.light.green,
              strokeStyle: learningPathReportColors.dark.green,
              ...commonStyles,
            },
            {
              text: 'Regular',
              fillStyle: learningPathReportColors.light.yellow,
              strokeStyle: learningPathReportColors.dark.yellow,
              ...commonStyles,
            },
            {
              text: 'Insatisfatório',
              fillStyle: learningPathReportColors.light.red,
              strokeStyle: learningPathReportColors.dark.red,
              ...commonStyles,
            },
          ];
        },
      },
    },
    tooltip: {
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    y: {
      suggestedMax: type === 'enem' ? 200 : 10,
      ticks: {
        stepSize: type === 'enem' ? 10 : 2,
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
  },
});
