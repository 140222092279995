import api from './api';

export const addLikeInReviewAnnotationService = (data: any) =>
  api(`/api/v1/review_annotations/${data.id}/like_info/`, {
    method: 'patch',
    data,
  });

export interface CreateGradeReviewAnnotationData {
  competence: number;
  sub_competence?: number;
  selected_text?: string;
  suggested_text?: string;
  comment: string;
  review: number | string;
  reviewer: number | string;
  review_subcategory?: number;
  annotation_type: string;
  reported_by?: number | string;
}

export const createGradeReviewAnnotationService = (
  data: CreateGradeReviewAnnotationData,
) =>
  api(`/api/v1/review_annotations/`, {
    method: 'post',
    data,
  });

export const updateGradeReviewAnnotationService = (data: any) =>
  api(`/api/v1/review_annotations/${data.id}/`, {
    method: 'patch',
    data,
  });

export const deleteGradeReviewAnnotationService = (
  gradeAnnotationId: string | number,
) =>
  api(`/api/v1/review_annotations/${gradeAnnotationId}/`, {
    method: 'delete',
  });

export const reportGradeReviewAnnotationService = (
  gradeAnnotationId: string | number,
) =>
  api(`/api/v1/review_annotations/${gradeAnnotationId}/report/`, {
    method: 'post',
  });
